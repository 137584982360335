import { IAppActionTypes, IAppActionCreator, IAppState } from 'models/IAppState';

const initialState: IAppState = {
  isLoading: false,
  rewardsList: [],
  rewardCategories: [],
  redeemedRewards: [],
  testing: [],
  language: [],
  activeCustomerinRewards: {},
  rewardCount: 0,
};

const reducer = (state = initialState, { type, payload }: IAppActionCreator) => {
  switch (type) {
    case IAppActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case IAppActionTypes.SET_EVENTSLIST: {
      return {
        ...state,
        rewardsList: payload,
      };
    }
    case IAppActionTypes.SET_REWARDS_LIST_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IAppActionTypes.SET_REWARDS_LIST_SUCCESS: {
      return {
        ...state,
        rewardsList: payload.data,
        isLoading: false,
        rewardCount: payload.count,
      };
    }
    case IAppActionTypes.SET_REWARDS_LIST_FAILURE: {
      return {
        ...state,
        rewardsList: [],
        isLoading: false,
      };
    }

    case IAppActionTypes.SET_REWARD_CATEGORIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IAppActionTypes.SET_REWARD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        rewardCategories: payload,
        isLoading: false,
      };
    }
    case IAppActionTypes.SET_REWARD_CATEGORIES_FAILURE: {
      return {
        ...state,
        rewardCategories: [],
        isLoading: false,
      };
    }

    case IAppActionTypes.SET_REDEEMED_REWARDS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IAppActionTypes.SET_REDEEMED_REWARDS_SUCCESS: {
      return {
        ...state,
        redeemedRewards: payload,
        isLoading: false,
      };
    }
    case IAppActionTypes.SET_REDEEMED_REWARDS_FAILURE: {
      return {
        ...state,
        redeemedRewards: [],
        isLoading: false,
      };
    }

    case IAppActionTypes.SET_LANGUAGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case IAppActionTypes.SET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        language: payload,
        isLoading: false,
      };
    }
    case IAppActionTypes.SET_LANGUAGE_FAILURE: {
      return {
        ...state,
        language: [],
        isLoading: false,
      };
    }
    case IAppActionTypes.SET_ACTIVE_CUSTOMER_IN_REWARDS: {
      return {
        ...state,
        activeCustomerinRewards: payload,
      };
    }

    case IAppActionTypes.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
